<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-form class="addForm" ref="form" :rules="couponRules" :model="formData" label-width="120px">
      <el-form-item label="姓名" prop="username">
        <el-input v-model="formData.username" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="username">
        <el-input v-model="formData.mobile" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="face">
        <FileUpload :url.sync="formData.face"></FileUpload>
      </el-form-item>
      <el-form-item label="工作单位" prop="work_unit">
        <el-input v-model="formData.work_unit" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="毕业院校" prop="graduate_school">
        <el-input v-model="formData.graduate_school" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-switch v-model="formData.is_publish" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="formData.type">
          <el-radio :label="1">法律顾问</el-radio>
          <el-radio :label="2">律师</el-radio>
          <el-radio :label="3">公证员</el-radio>
          <el-radio :label="4">法律服务工作者</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="执行地区" prop="work_address">
        <SelectAreaAll v-model="formData.handle_area_detail"></SelectAreaAll>
      </el-form-item>
      <el-form-item prop="adept_types" label="擅长类型">
        <el-tag :key="tag" v-for="tag in formData.adept_types" closable :disable-transitions="false" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 擅长处理</el-button>
      </el-form-item>
      <el-form-item prop="intro" label="详细描述">
        <el-input type="textarea" rows="15" placeholder="请输入内容" v-model="formData.intro" maxlength="500" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { detailAPI, editAPI, createAPI } from './api'
import FileUpload from '@/components/uploader/uploader-img.vue'
import SelectAreaAll from '@/views/components/select-area-all-mult.vue'
export default {
  name: 'addOrEdit',
  components: { FileUpload, SelectAreaAll },

  data() {
    return {
      inputVisible: false,
      inputValue: '',
      rules: [],
      formData: {
        username: '',
        work_unit: '',
        face: '',
        type: 0,
        graduate_school: '',
        handle_area_detail: [],
        intro: '',
        adept_types: [],
        is_publish: 1,
        mobile: ''
      },
      dialogVisible: false,
      currentPage: 1,
      page_size: 5,
      total: 0,
      list: [],
      searchData: {},
      couponRules: {}
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  computed: {},
  watch: {},
  methods: {
    handleClose(tag) {
      this.formData.adept_types.splice(this.formData.adept_types.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.formData.adept_types.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            createAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await detailAPI(this.$route.params.id)
    },
    selectGoods() {
      this.dialogVisible = true
    },
    handleSelectionChange(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.wrapper /deep/ {
  width: 100%;

  .addForm {
    .el-form-item {
      .el-input {
        width: 400px;
      }

      .tips {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 30px;
      }

      .el-range-separator {
        padding: 0;
      }
    }
  }

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
}
</style>
